<!--
/**
 * Intro template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import store from '?/Stores';
    import { routeTo, getAssetPathForIntro } from '?/Composable/Tools';
    import AudioPlayer from '?/Component/Library/AudioPlayer';

    // Data
    const state = reactive({
        step: 1,
        stepCount: 3
    });
</script>

<template>
    <div class="intro-wrapper">
        <div class="intro-content">
            <template v-if="state.step === 1">
                <figure class="intro-teaser">
                    <img src="/assets/layout/logo-full-inverted.png"
                         alt="Eleonores Tagebücher"
                         class="logo-full" />
                    <figcaption>
                        Spaziergänge durch Bad Lausick
                    </figcaption>
                </figure>
                <h2>Willkommen!</h2>
                <p>
                    Ihre Zeitreise durch Bad Lausick kann sofort losgehen. Wenn Sie im Browser Ihre Standortdaten (GPS) freigeben, erhalten Sie individuelle Empfehlungen.
                </p>
                <p>
                    Keine Sorge: Ihre Daten werden weder gespeichert noch an den Server übertragen.
                </p>
            </template>
            <template v-if="state.step === 2">
                <h2>Ich grüße Sie!</h2>
                <p>
                    Mein Name ist Phileas.<br />
                    Als Hobby-Historiker habe ich es mir zur Aufgabe gemacht, persönliche Geschichten von früher zum Leben zu erwecken. Ich freue mich, dass Sie mich auf meinen Spaziergängen durch Bad Lausick begleiten möchten!

                </p>
            </template>
            <template v-if="state.step === 3">
                <h2>Die Funktionen</h2>
                <img src="/assets/media/info-experience.png" alt="Brille" width="170" height="170" style="width: 60px; margin-right: auto; margin-bottom: .5rem; margin-left: auto;" />
                <p>
                    Aktivieren Sie hier Spaziergänge und erkunden Sie Bad Lausick.
                </p>
                <img src="/assets/media/info-explore.png" alt="Uhr" width="170" height="170" style="width: 60px; margin-right: auto; margin-bottom: .5rem; margin-left: auto;" />
                <p>
                    Sehen Sie ein Ausrufezeichen an der Uhr links oben, gibt es etwas Neues zu entdecken!
                </p>
            </template>
        </div>
        <div class="intro-controls">
            <button type="button"
                    class="intro-controls-previous"
                    v-if="state.step > 1"
                    @click="state.step--">
                {{ $t('intro.previous') }}
            </button>
            <button type="button"
                    class="intro-controls-next"
                    v-if="state.step < state.stepCount"
                    @click="state.step++">
                {{ $t('intro.next') }}
            </button>
            <button type="button"
                    class="intro-controls-final"
                    v-if="state.step === state.stepCount"
                    @click="routeTo('/webapp/station')">
                {{ $t('intro.go-to-stations') }}
            </button>
            <span class="intro-controls-meta">
                {{ state.step }}/{{ state.stepCount }}
            </span>
        </div>
    </div>
</template>

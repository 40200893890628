/**
 * Get asset path for videos
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import config from '?/Config';

/**
  * Get asset path for videos
  *
  * @return {string} Asset path
  */
export const getAssetPathForVideos = () => {
    let basePath = 'https://tr-cst-bls-video.fra1.digitaloceanspaces.com';
    let assetPrefix = 'P' + config.projectId + '_';

    return basePath + '/' + assetPrefix;
};
